import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "payment-api-reference"
    }}>{`Payment API Reference`}</h1>
    <p>{`Openapi 3: `}<a parentName="p" {...{
        "href": "https://api.entur.io/api-docs/payment?group=public"
      }}>{`https://api.entur.io/api-docs/payment?group=public`}</a></p>
    <p>{`The timeout for all Payment endpoints is set to 62 seconds, which is different from most Entur endpoints, which have 55 seconds.
This is due to some Payment Service Providers having 60 second timeouts on their endpoints.`}</p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/api-docs/payment?group=public" baseUrl="https://api.entur.io/sales" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      